import moment from 'moment-timezone'

export interface PujaMedia {
  media_type: string
  media_url: string
  media_description: string
  media_thumbnail: string
}

export interface PujaUpdateForm {
  puja_start_time: string
  puja_end_time: string
  puja_media: PujaMedia[]
  new_puja_state:
    | 'STARTED'
    | 'COMPLETED'
    | 'SCHEDULED'
    | 'BOOKED'
    | 'CANCELLED'
    | 'RESCHEDULED'
  message_cards: { header: string; description: string }[]
  priest_medias: PujaMedia[]
}

export function parseStoreResponse(storeResponseData: any) {
  return {
    ...storeResponseData,
    puja_start_time: storeResponseData?.puja_start_time
      ? moment(
          moment(storeResponseData?.puja_start_time).format(
            'YYYY-MM-DD HH:mm:ss',
          ),
        ).utcOffset(0)
      : undefined,
    puja_end_time: storeResponseData?.puja_end_time
      ? moment(
          moment(storeResponseData?.puja_end_time).format(
            'YYYY-MM-DD HH:mm:ss',
          ),
        ).utcOffset(0)
      : undefined,
    puja_media: storeResponseData?.puja_media?.map((media: any) => ({
      ...media,
      media_url: media?.media_url ? [media?.media_url] : undefined,
      media_thumbnail: media?.media_thumbnail
        ? [media?.media_thumbnail]
        : undefined,
    })),
    priest_medias: storeResponseData?.priest_medias?.map((media: any) => ({
      ...media,
      media_url: media?.media_url ? [media?.media_url] : undefined,
      media_thumbnail: media?.media_thumbnail
        ? [media?.media_thumbnail]
        : undefined,
    })),
    message_cards: storeResponseData?.message_cards?.map((media: any) => ({
      ...media,
      cta_media: {
        ...media?.cta_media,
        media_url: media?.cta_media?.media_url
          ? media?.cta_media?.media_url?.includes('youtube')
            ? media?.cta_media?.media_url
            : [media?.cta_media?.media_url]
          : undefined,
        media_type: media?.cta_media?.media_url?.includes('youtube')
          ? 'url'
          : 'media',
        media_thumbnail: media?.cta_media?.media_thumbnail
          ? [media?.media_thumbnail]
          : undefined,
      },
    })),
  }
}

export const formatDateToIsoStringTime = (formDate: any) => {
  let processed_date

  if (formDate && formDate._isAMomentObject) {
    if (!formDate.isUTC()) {
      formDate.add(5, 'hours').add(30, 'minutes')
    }
    processed_date = formDate.toDate()
  } else {
    processed_date = new Date(formDate)
  }

  return processed_date.toISOString()
}

export function preparePayload(data: PujaUpdateForm) {
  return {
    puja_start_time: data?.puja_start_time
      ? formatDateToIsoStringTime(data?.puja_start_time)
      : null,
    puja_end_time: data?.puja_end_time
      ? formatDateToIsoStringTime(data?.puja_end_time)
      : null,
    puja_media:
      data?.puja_media?.length > 0
        ? data?.puja_media?.map((item: any) => {
            const fileUrl = item?.media_url?.[0]
            const fileExtension = fileUrl?.split?.('.')?.pop?.()
            const mediaType = getFileFormat(fileExtension)
            return {
              ...item,
              media_type: mediaType,
              media_url: fileUrl,
              media_thumbnail: item?.media_thumbnail?.[0],
            }
          })
        : null,
    new_puja_state: data?.new_puja_state,
    message_cards:
      data?.message_cards?.length > 0
        ? data?.message_cards?.map((item: any) => {
            const fileUrl = item?.cta_media?.media_url?.[0]
            const fileExtension = fileUrl?.split?.('.')?.pop?.()
            const mediaType = getFileFormat(fileExtension)
            return {
              ...item,
              cta_media: {
                ...item?.cta_media,
                media_type:
                  item?.cta_media?.media_type === 'url' ? 'url' : mediaType,
                media_url:
                  item?.cta_media?.media_type === 'url'
                    ? item?.cta_media?.media_url
                    : item?.cta_media?.media_url?.[0],
                media_thumbnail: item?.cta_media?.media_thumbnail?.[0],
              },
            }
          })
        : null,
    priest_medias:
      data?.priest_medias?.length > 0
        ? data?.priest_medias?.map((item: any) => {
            const fileUrl = item?.media_url?.[0]
            const fileExtension = fileUrl?.split?.('.')?.pop?.()
            const mediaType = getFileFormat(fileExtension)
            return {
              ...item,
              media_type: mediaType,
              media_url: fileUrl,
              media_thumbnail: item?.media_thumbnail?.[0],
            }
          })
        : null,
  }
}

export const getFileFormat = (fileExtension: string): string | null => {
  switch (fileExtension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'webp':
    case 'gif':
      return 'image'

    case 'mp4':
      return 'video'

    default:
      return null
  }
}
