import { useAppContext } from '@/components/AppContext';
import withDefaultLayout from '@/hoc/WithDefaultLayout';
import { A4B_APPS } from '@/utils/constants';
import { Table, Checkbox, message, Card, Modal } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`;

interface Review {
  id: string;
  date: string;
  language: string;
  rating: number;
  suggestion: string;
  is_moderated: boolean;
}

const ManageReviews = () => {
  const { networkInstance, app, languageState } = useAppContext();
  const [tableDataService, setTableDataService] = useState<Review[]>([]);
  const [showUpdateReviewModeratedModal, setShowUpdateReviewModeratedModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState<Review | null>(null);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 50, total: 0 });

  const monetisationPujaApi = app === A4B_APPS.SRIMANDIR
    ? networkInstance.clientWithHeaders.monetisationPujaApi
    : networkInstance.clientWithHeaders.monetisationAstroPujaApi;

  const getAllReviews = useCallback(async (currentPage = 1) => {
    try {
      const reviewsResponse = await monetisationPujaApi?.userReviewsApi?.getAllReviews(
        { page_no: currentPage },
        languageState?.monetisationLanguage
      );

      if (reviewsResponse) {
        const reviews: Review[] = reviewsResponse?.data?.data?.reviews;
        const isLastPage = reviewsResponse?.data?.data?.is_last_page;

        setTableDataService((prevReviews) => [...prevReviews, ...reviews]);

        if (!isLastPage) {
          getAllReviews(currentPage + 1);
        } else {
          setPagination((prevPagination) => ({
            ...prevPagination,
            total: currentPage * prevPagination.pageSize!,
          }));
        }
      }
    } catch (error) {
      message.error('Failed to fetch reviews.');
    }
  }, [languageState?.monetisationLanguage, monetisationPujaApi]);

  useEffect(() => {
    setTableDataService([]);
    getAllReviews();
  }, [languageState?.monetisationLanguage, getAllReviews]);

  const columns = [
    { title: 'Review Id', dataIndex: 'id', key: 'id' },
    { title: 'Date', dataIndex: 'date', key: 'date' },
    { title: 'Language', dataIndex: 'language', key: 'language' },
    { title: 'Star Rating', dataIndex: 'rating', key: 'rating' },
    {
      title: 'Additional comment',
      dataIndex: 'suggestion',
      key: 'suggestion',
      width: 500, // Set a fixed width for the column
      render: (text: any) => (
        <div style={{ wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },    {
      title: 'Moderated',
      dataIndex: 'moderated',
      key: 'moderated',
      render: (text: any, record: Review) => (
        <Checkbox
          checked={record?.is_moderated}
          onChange={() => {
            setSelectedReview(record);
            setShowUpdateReviewModeratedModal(true);
          }}
        />
      ),
    },
  ];

  const handleModeratedChange = async (record: Review) => {
    try {
      const updatedStatus = !record.is_moderated;
      await monetisationPujaApi?.userReviewsApi?.updateReviewModerated(
        record?.id,
        { is_moderated: updatedStatus },
        languageState?.monetisationLanguage
      );

      setTableDataService((prevReviews) =>
        prevReviews.map((review) =>
          review.id === record.id
            ? { ...review, is_moderated: updatedStatus }
            : review
        )
      );
    } catch (error) {
      message.error('Failed to update moderated status.');
    }
  };

  const handleTableChange = (newPagination: any) => {
    setPagination(newPagination);
    setTableDataService([]);
    getAllReviews(newPagination.current);
  };

  const onModalOk = () => {
    if (selectedReview) {
      handleModeratedChange(selectedReview);
    }
    setShowUpdateReviewModeratedModal(false);
  };

  const onModalCancel = () => {
    setShowUpdateReviewModeratedModal(false);
  };

  return (
    <div style={{ padding: '40px' }}>
      <Container style={{ width: '100%' }}>
        <Table
          dataSource={tableDataService}
          columns={columns}
          size="large"
          pagination={pagination}
          onChange={handleTableChange}
        />
      </Container>
      <Modal
        title="Update Moderation Status"
        visible={showUpdateReviewModeratedModal}
        onOk={onModalOk}
        onCancel={onModalCancel}
        okText="Yes, Update"
      >
        <p>Are you sure you want to update the moderation status?</p>
      </Modal>
    </div>
  );
};

export default withDefaultLayout(ManageReviews);
